import React, { useState, useEffect } from "react"
import moment from "moment"
import { gql, useLazyQuery } from "@apollo/client"

import { Button } from "../../anti/buttons/buttons"
import { Section, Container } from "../../anti/grid/grid"
import { Card } from "../../anti/card/card"
import { Link } from "../../anti/link/link"
import { useScrollAnim } from "src/components/hooks/hooks"
import { InputTextV2 } from "components/anti/form/form"

import imgDefault from "../../../assets/img/common/default.jpg"

export const ResourcesPageContent = ({
  resources,
  posts,
  lang,
  callBack,
  searchResult,
  loading,
  search,
}) => {
  const [trigger, anim] = useScrollAnim()
  const block = resources
  const dataTabs = [
    { name: "All", target: "all" },
    ...block.map(data => ({ name: data.name, target: data.slug })),
  ]

  //load more function
  const postsPerPage = 6
  let arrayForHoldingPosts = []

  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(6)

  const loopWithSlice = (start, end) => {
    const slicedPosts = posts.slice(start, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
  }, [])

  const handleShowMorePosts = () => {
    loopWithSlice(0, next + postsPerPage)
    setNext(next + postsPerPage)
  }

  //Search result

  const searchContent =
    lang === "EN"
      ? searchResult?.ENSearch?.edges
      : searchResult?.IDSearch?.edges || searchResult?.ENSearch?.edges

  return (
    <Section forwardRef={trigger} className="resources-content">
      <Container>
        <div className="search-header search-resources mb-3">
          <div className="row">
            <div className="col-lg-9">
              <div
                id="nav-tab"
                className="nav-pills-wrapper nav-scrollable-wrapper d-flex scrollable-fade resources-nav"
              >
                <div className="nav-scrollable ">
                  <ul className="nav nav-pills" id="experiment" role="tablist">
                    {dataTabs.map((nav, i) => (
                      <li className="nav-item" key={i}>
                        <Link
                          to={
                            nav.target === "all"
                              ? `/resources`
                              : `/resources/${nav.target}`
                          }
                          className={`nav-link text-muted h4`}
                          activeClassName={!searchContent ? `active` : ``}
                        >
                          {nav.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-right align-items-start">
              <InputTextV2
                onKeyPress={e => callBack(e)}
                placeholder={
                  lang === "EN" ? "Enter keyword(s)" : "Masukkan kata kunci"
                }
              >
                <div className="form-search-icon">
                  <i className="air ai-search" />
                </div>
              </InputTextV2>
            </div>
          </div>
        </div>
        {!search && (
          <>
            <div className="row row-3">
              {postsToShow.map((data, i) => {
                const dataLang =
                  lang === "EN" ? data : data?.translation || data
                const img = dataLang?.featuredImage
                  ? dataLang?.featuredImage?.node?.sourceUrl
                  : imgDefault

                const author = dataLang?.author?.node?.name
                // const categories = dataLang?.categories.nodes[0].name
                const categories = dataLang?.categories?.nodes
                  .map(e => e.name)
                  .join(", ")
                return (
                  <div className="col-md-6 col-lg-4" key={i}>
                    <Card
                      variant="boxless"
                      img={img}
                      imgRatio="r-3-2"
                      label={categories}
                      title={dataLang.title}
                      titleClassName={`${anim(2)}`}
                      labelClassName={`text-uppercase ${anim(1)}`}
                      className={`mb-3 card-resource ${anim(
                        1 + i,
                        "revealBlockInRight"
                      )}`}
                      link={`/resources/details/${data.slug}`}
                    >
                      <p className={`card-text ${anim(3)}`}>{`${moment(
                        dataLang.date
                      ).format("DD MMM YYYY")} • By ${author}`}</p>
                      <Button variant="link" className={`${anim(4)}`}>
                        {lang === "EN" ? "Read More" : "Baca Selengkapnya"}
                      </Button>
                    </Card>
                  </div>
                )
              })}
            </div>
            {postsToShow.length != 0 && postsToShow.length != posts.length && (
              <div className="d-flex justify-content-center w-100">
                <Button variant="primary" onClick={handleShowMorePosts}>
                  Load More
                </Button>
              </div>
            )}
          </>
        )}
        {loading && (
          <div className="loading-page">
            <div className="loading-wrapper">
              <i class="fas fa-spinner fa-pulse"></i>
            </div>
          </div>
        )}
        {!loading && searchContent && (
          <div className="row row-3">
            {searchContent.map((data, i) => {
              const realData = data?.node
              const img = realData?.featuredImage
                ? realData?.featuredImage?.node?.sourceUrl
                : imgDefault

              const author = realData?.author?.node?.name
              // const categories = dataLang?.categories.nodes[0].name
              const categories = realData?.categories?.nodes
                .map(e => e.name)
                .join(", ")

              const slug = realData?.translation?.slug
              return (
                <div className="col-md-6 col-lg-4" key={i}>
                  <Card
                    variant="boxless"
                    img={img}
                    imgRatio="r-3-2"
                    label={categories}
                    title={realData.title}
                    titleClassName={`${anim(2)}`}
                    labelClassName={`text-uppercase ${anim(1)}`}
                    className={`mb-3 card-resource ${anim(
                      1 + i,
                      "revealBlockInRight"
                    )}`}
                    link={`/resources/details/${slug}`}
                  >
                    <p className={`card-text ${anim(3)}`}>{`${moment(
                      realData.date
                    ).format("DD MMM YYYY")} • By ${author}`}</p>
                    <Button variant="link" className={`${anim(4)}`}>
                      {lang === "EN" ? "Read More" : "Baca Selengkapnya"}
                    </Button>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
        {!loading && searchContent?.length <= 0 && (
          <div className="result-none">
            <p className="mb-0">
              {lang === "EN"
                ? "Please try different keyword(s)"
                : "Masukkan kata kunci yang lain"}
            </p>
          </div>
        )}
      </Container>
    </Section>
  )
}
