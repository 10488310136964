import React, { useContext, useEffect } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { navigate } from "gatsby-link"

import Layout from "components/layout"
import Seo from "components/seo"

//Load Components
import { ResourcesPageFeatured } from "../components/shift/resources/featured"
import { ResourcesPageContent } from "../components/shift/resources/content"
import { AboutRequest } from "components/shift/about/request"
import { WithLocation } from "components/anti/utils/utils"

//Load Context
import { LangContext } from "../context/lang-context"

const ResourcesCategoriesPage = ({ pageContext, search }) => {
  const [getContent, { data, loading }] = useLazyQuery(SEARCH_RESOURCES_QUERY)
  const { lang } = useContext(LangContext)

  useEffect(() => {
    if (!data && search.q) {
      getContent({ variables: { search: search.q } })
    }
  }, [])

  const searchCb = e => {
    navigate(`/resources?q=${e}`)
    getContent({ variables: { search: e } })
  }

  const categories = pageContext?.categories
  const posts = pageContext?.posts
    ? pageContext?.posts
    : pageContext?.data?.posts?.nodes
  const featured = pageContext?.tag?.posts?.nodes

  const request =
    lang === "EN"
      ? pageContext?.request?.aboutPage
      : pageContext?.request?.translation?.aboutPage ||
        pageContext?.request?.aboutPage

  return (
    <Layout>
      <Seo title="Resources" />
      <ResourcesPageFeatured data={featured[0]} lang={lang} />
      <ResourcesPageContent
        resources={categories}
        posts={posts}
        lang={lang}
        callBack={searchCb}
        searchResult={data}
        loading={loading}
        search={search?.q}
      />
      <AboutRequest data={request.request} />
    </Layout>
  )
}

const SEARCH_RESOURCES_QUERY = gql`
  query searchResources($search: String) {
    ENSearch: contentNodes(
      where: { search: $search, contentTypes: [POST], language: EN }
      first: 100
    ) {
      edges {
        cursor
        node {
          slug
          ... on Post {
            id
            title
            slug
            excerpt
            translation(language: EN) {
              slug
            }
            author {
              node {
                name
              }
            }
            categories {
              nodes {
                name
              }
            }
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
    IDSearch: contentNodes(
      where: { search: $search, contentTypes: [POST], language: ID }
      first: 100
    ) {
      edges {
        cursor
        node {
          slug
          ... on Post {
            id
            title
            slug
            excerpt
            translation(language: EN) {
              slug
            }
            author {
              node {
                name
              }
            }
            categories {
              nodes {
                name
              }
            }
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`

export default WithLocation(ResourcesCategoriesPage)
