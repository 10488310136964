import React from "react"
import { Button } from "../../anti/buttons/buttons"
import { Breadcrumb } from "../../anti/breadcrumb/breadcrumb"
import { Section, Container } from "../../anti/grid/grid"
import { Card } from "components/anti/card/card"

import gambar from "../../../assets/img/common/Zoom.jpg"
import { Link } from "gatsby"
import moment from "moment"

import { useScrollAnim } from "src/components/hooks/hooks"

export const ResourcesPageFeatured = ({ data, lang }) => {
  const [trigger, anim] = useScrollAnim()

  const block = lang === "EN" ? data : data?.translation || data
  const image = block?.featuredImage?.node?.sourceUrl
  const author = block?.author?.node?.name
  const categories = block?.categories?.nodes.map(e => e.name).join(", ")
  return (
    <>
      {!block && (
        <Container>
          <div className="breadcrumb-wrapper pt-5">
            {<Breadcrumb path={"/resources"} theme="light" />}
          </div>
        </Container>
      )}
      {block && (
        <Section forwardRef={trigger} className="resources-featured pt-4 pb-0">
          <Container>
            <div className="breadcrumb-wrapper">
              {<Breadcrumb path={"/resources"} theme="light" />}
            </div>
            <div className="row row-3">
              <div className="col-lg-6">
                <Card
                  isLink={false}
                  variant="boxless"
                  img={image}
                  imgRatio="r-3-2"
                  className={`${anim(1, "revealBlockInRight")}`}
                />
              </div>
              <div className="col-lg-6 col-text">
                <h1 className={`h6 text-uppercase ${anim(2)}`}>{categories}</h1>
                <h2 className={`${anim(3)}`}>{block?.title}</h2>
                <div
                  className={`${anim(4)}`}
                  dangerouslySetInnerHTML={{ __html: block?.excerpt }}
                />
                <p className={`h6 ${anim(5)}`}>{`${moment(block?.date).format(
                  "DD MMM YYYY"
                )} • By ${author}`}</p>
                <Link
                  to={`/resources/details/${data?.slug}`}
                  className={`${anim(6)}`}
                >
                  <Button variant="link">
                    {lang === "EN" ? "Read More" : "Baca Selengkapnya"}
                  </Button>
                </Link>
              </div>
            </div>
          </Container>
          <div className="resources-featured-bg">
            <Container>
              <div className="graphic-1" />
              <div className="graphic-2" />
            </Container>
          </div>
        </Section>
      )}
    </>
  )
}
